<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Procesar resultados"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  En esta sección hay tres procesos, <b>migrar las notas</b> de
                  prueba escrita y evaluación de la lengua originaria,
                  <b>calcular resultados</b> y <b>procesar admitidos</b> de la
                  modalidades <b>A</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-tabs
                  v-model="tab"
                  background-color="cyan lighten-5"
                  fixed-tabs
                  align-with-title
                >
                  <v-tabs-slider color="teal"></v-tabs-slider>

                  <v-tab color="teal" v-for="item in tabs" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items class="mt-3" v-model="tab">
                  <v-tab-item v-for="item in tabs" :key="item">
                    <div v-if="tab === 0">
                      <v-form ref="forms" @submit.prevent="searchApplicants()">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-select
                              label="Instituciones"
                              v-model="options.institucion_id"
                              :items="instituciones"
                              item-text="nombre"
                              item-value="id"
                              :rules="[(v) => !!v || 'Seleccione una opción']"
                              @change="getSpecialties()"
                              hide-details
                              filled
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              label="Especialidades"
                              v-model="options.especialidad_id"
                              :items="especialidades"
                              item-text="descripcion"
                              item-value="id"
                              :rules="[(v) => !!v || 'Seleccione una opción']"
                              @change="getApplicants()"
                              hide-details
                              filled
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-btn
                              class="mr-1"
                              type="button"
                              large
                              color="error"
                              :loading="load_report"
                              @click="reportResultModAPdf"
                            >
                              <v-icon>mdi-file-download</v-icon>
                              A
                            </v-btn>
                            <v-btn
                              type="button"
                              large
                              color="error"
                              :loading="load_report2"
                              @click="reportResultModBPdf"
                            >
                              <v-icon>mdi-file-download</v-icon>
                              B
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="options.search"
                              label="CI o DNI, nombre, apellidos"
                              :rules="[(v) => !!v || '']"
                              @input="changeSearch"
                              hint="1234567"
                              hide-details
                              clearable
                              filled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-btn type="submit" large color="primary">
                              <v-icon>mdi-magnify</v-icon>
                              Buscar
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="4" class="text-center">
                            <b>Cantidad admitidos</b>
                            <h1 class="teal--text">{{ total }}</h1>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-data-table
                        :loading="loading"
                        :headers="headers"
                        :items="postulantes"
                        :page.sync="options.page"
                        :items-per-page="options.itemsPerPage"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template v-slot:[`item.num`]="{ item }">
                          <td>
                            {{
                              (options.page - 1) * options.itemsPerPage +
                              (postulantes.indexOf(item) + 1)
                            }}
                          </td>
                        </template>
                        <template v-slot:[`item.escrita`]="{ item }">
                          <td class="blue--text">
                            {{
                              item.porcentaje_escrita
                                ? item.porcentaje_escrita.substr(0, 5)
                                : "-"
                            }}
                          </td>
                        </template>
                        <!-- <template v-slot:[`item.aptitud`]="{ item }">
                          <td class="blue--text">
                            {{
                              item.porcentaje_aptitud
                                ? item.porcentaje_aptitud.substr(0, 5)
                                : "-"
                            }}
                          </td>
                        </template> -->
                        <template v-slot:[`item.estado`]="{ item }">
                          <td class="blue--text">
                            <v-chip
                              v-if="
                                item.a_postulante.estado_id == 8 ||
                                item.a_postulante.estado_id == 15
                              "
                              color="success lighten-1"
                              small
                              class="ma-2"
                            >
                              admitido
                            </v-chip>
                            <v-chip
                              v-else
                              color="error lighten-1"
                              small
                              class="ma-2"
                            >
                              no admitido
                            </v-chip>
                          </td>
                        </template>
                        <template v-slot:[`item.accion`]="{ item }">
                          <td>
                            <v-btn
                              small
                              color="primary"
                              @click="switchAdmited(item.a_postulante)"
                            >
                              {{
                                item.a_postulante.estado_id == 8 ||
                                item.a_postulante.estado_id == 15
                                  ? "Cancelar"
                                  : "Admitir"
                              }}
                            </v-btn>
                          </td>
                        </template>
                      </v-data-table>
                      <div class="v-data-footer">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <div class="v-data-footer__select">
                              Filas por página:
                              <v-select
                                :items="[25, 50, 100]"
                                v-model="options.itemsPerPage"
                                @change="changePerPage()"
                                hide-details
                                class="my-0"
                              ></v-select>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="8" class="text-center">
                            <v-pagination
                              v-model="options.page"
                              :length="options.pageCount"
                              :total-visible="5"
                              @input="changePage"
                            ></v-pagination>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h4 class="grey--text">PRUEBA ESCRITA</h4>
                          <p>
                            Se migra las notas de los resultados obtenidos de la
                            prueba escrita, desde los registros proporcionados
                            por la empresa.
                          </p>
                          <div class="text-center">
                            <v-btn
                              type="button"
                              color="primary"
                              :loading="load_write"
                              @click="migrateWrite"
                              :disabled="btn_write"
                            >
                              Migrar
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <h4 class="grey--text">EVALUACIÓN DE LA LENGUA ORINARIA</h4>
                          <p>
                            Se migra las notas de los resultados obtenidos de la
                            evaluación de la lengua originaria, registrados por las Escuelas
                            Superiores de Formación de Mastros y Unidades
                            Académicas.
                          </p>
                          <div class="text-center">
                            <v-btn
                              type="button"
                              color="primary"
                              :loading="load_aptitude"
                              @click="migrateAptitude"
                              :disabled="btn_aptitude"
                            >
                              Migrar
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h4 class="grey--text">CALCULAR RESULTADO</h4>
                          <p>
                            Se calcula las notas de los resultados obtenidos de
                            la prueba escrita. En caso de tener prueba de
                            aptitud se aplicará la siguiente regla: prueba
                            escrita (70%) y aprueba de aptitud (30%), sumados se
                            obtiene el resultado final.
                          </p>
                          <div class="text-center">
                            <v-btn
                              type="button"
                              color="primary"
                              :loading="load_calculate"
                              @click="calculateResult"
                              :disabled="btn_result"
                            >
                              Calcular
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <h4 class="grey--text">PROCESAR ADMITIDOS</h4>
                          <p>
                            Se procesa admitidos, por cada Escuelas Superiores
                            de Formación de Maestros y Unidades Académicas. Una
                            vez procesada de manera automatica, se debe
                            verificar si existe empate en resultado final y
                            proceder con admitir si corresponde.
                          </p>
                          <div class="text-center">
                            <v-btn
                              type="button"
                              color="primary"
                              :loading="load_process"
                              @click="processAdmited"
                              :disabled="btn_process"
                            >
                              Procesar
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
    <v-dialog v-model="messageDialog" persistent width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Notificación</span>
        </v-card-title>
        <v-card-text class="text-center">
          {{ message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="messageDialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Util from "../utils/general";
import PDFService from "../utils/pdf";
import Service from "../services/request";
import { mapActions } from "vuex";

export default {
  name: "admission-process-result",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_write: false,
    load_aptitude: false,
    load_calculate: false,
    load_report: false,
    load_report2: false,
    load_process: false,
    btn_write: false,
    btn_aptitude: false,
    btn_result: false,
    btn_process: false,

    convocatoria: {
      id: null,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      {
        text: "Apellidos y nombres",
        value: "a_postulante.a_persona.nombre_completo",
        sortable: false,
      },
      {
        text: "Cédula identidad",
        value: "a_postulante.a_persona.cedula_dni",
        sortable: false,
      },
      { text: "Butaca", value: "butaca", sortable: false },
      //{ text: "Aptitud (30%)", value: "porcentaje_aptitud", sortable: false },
      { text: "Escrita", value: "porcentaje_escrita", sortable: false },
      { text: "Nota", value: "resultado", sortable: false },
      { text: "Obs.", value: "filtro", sortable: false },
      { text: "Estado", value: "estado", sortable: false },
      { text: "Acciones", value: "accion", sortable: false },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      especialidad_id: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 25,
    },
    instituciones: [],
    especialidades: [],
    postulantes: [],
    postulante: {},
    tab: null,
    tabs: ["2. RESULTADO Y ADMISIÓN", "1. MIGRAR Y CALCULAR"],
    //tabs: ["2. RESULTADO Y ADMISIÓN"],
    message: "",
    messageDialog: false,
    user: null,
  }),
  computed: {
    total() {
      return this.postulantes.reduce(
        (a, b) =>
          a +
          (b.a_postulante.estado_id == 8 || b.a_postulante.estado_id == 9
            ? 1
            : 0),
        0
      );
    },
  },
  created() {
    this.user = Util.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [1,13];
    if (this.user && roles.includes(this.user.rid)) {
      this.getInstitutionParam();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getInstitutionParam() {
      this.notify_load = true;
      Service.svcInsitutionParam()
        .then((response) => {
          if (response.status === 200) {
            this.convocatoria = response.data.convocatorias?response.data.convocatorias[0]:{ id: null, cite: "", gestion: "" };
            this.instituciones = response.data.instituciones;
            this.options.convocatoria_id = this.convocatoria.id;
          }
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getSpecialties() {
      this.options.especialidad_id = "";
      this.postulantes = [];
      this.options.page = 1;
      this.options.pageCount = 0;
      this.options.itemsPerPage = 25;
      Service.svcSpecialties(this.options.institucion_id, true)
        .then((response) => {
          this.especialidades = response.data;
        })
        .catch(() => {
          console.error("Sin especialidades");
        });
    },

    migrateWrite() {
      this.message = "";
      this.load_write = true;
      this.btn_aptitude = true;
      this.btn_result = true;
      this.btn_process = true;
      Service.svcMigrateWrite(this.convocatoria.id)
        .then((response) => {
          this.load_write = false;
          this.btn_aptitude = false;
          this.btn_result = false;
          this.btn_process = false;
          if (response.status === 201) {
            this.message = response.data;
            this.messageDialog = true;
          } else {
            this.toast({ color: "info", text: response.data });
          }
        })
        .catch(() => {
          this.load_write = false;
          this.btn_aptitude = false;
          this.btn_result = false;
          this.btn_process = false;
        });
    },

    migrateAptitude() {
      this.message = "";
      this.load_aptitude = true;
      this.btn_write = true;
      this.btn_result = true;
      this.btn_process = true;
      Service.svcMigrateAptitude(this.convocatoria.id)
        .then((response) => {
          this.load_aptitude = false;
          this.btn_write = false;
          this.btn_result = false;
          this.btn_process = false;
          if (response.status === 201) {
            this.message = response.data;
            this.messageDialog = true;
          } else {
            this.toast({ color: "info", text: response.data });
          }
        })
        .catch(() => {
          this.load_aptitude = false;
          this.btn_write = false;
          this.btn_result = false;
          this.btn_process = false;
        });
    },

    calculateResult() {
      this.message = "";
      this.load_calculate = true;
      this.btn_write = true;
      this.btn_aptitude = true;
      this.btn_process = true;
      Service.svcCalculateResult(this.convocatoria.id)
        .then((response) => {
          this.load_calculate = false;
          this.btn_write = false;
          this.btn_aptitude = false;
          this.btn_process = false;
          if (response.status === 201) {
            this.message = response.data;
            this.messageDialog = true;
          } else {
            this.toast({ color: "info", text: response.data });
          }
        })
        .catch(() => {
          this.load_calculate = false;
          this.btn_write = false;
          this.btn_aptitude = false;
          this.btn_process = false;
        });
    },

    processAdmited() {
      this.message = "";
      this.load_process = true;
      this.btn_write = true;
      this.btn_aptitude = true;
      this.btn_result = true;
      Service.svcProcessAdmited(this.convocatoria.id)
        .then((response) => {
          this.load_process = false;
          this.btn_write = false;
          this.btn_aptitude = false;
          this.btn_result = false;
          if (response.status === 201) {
            this.message = response.data;
            this.messageDialog = true;
          } else {
            this.toast({ color: "info", text: response.data });
          }
        })
        .catch(() => {
          this.load_process = false;
          this.btn_write = false;
          this.btn_aptitude = false;
          this.btn_result = false;
        });
    },

    getApplicants() {
      this.loading = true;
      Service.svcApplicants("admision/postulantes/resultado", this.options)
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    searchApplicants() {
      this.getApplicants();
      if (this.$refs["forms"][0].validate()) {
        this.getApplicants();
      } else {
        this.toast({ color: "info", text: "Ingrese datos para buscar" });
      }
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getApplicants();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getApplicants();
    },

    changePage() {
      this.getApplicants();
    },

    switchAdmited(postulante) {
      Service.svcSwitchAdmited({
        id: postulante.id,
        estado_id: postulante.estado_id,
      })
        .then((response) => {
          if (response.status == 200) {
            this.getApplicants();
            this.toast({ color: "success", text: "Postulante admitido" });
          } else {
            this.toast({ color: "error", text: "Postulante no admitido" });
          }
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    reportResultModAPdf() {
      this.load_report = true;
      Service.svcReportAdmittedModA(this.convocatoria.id)
        .then((response) => {
          PDFService.reportAdmittedModA(this.convocatoria, response);
          this.load_report = false;
        })
        .catch((error) => {
          this.load_report = false;
          console.error("No genero el reporte", error);
        });
    },
    reportResultModBPdf() {
      this.load_report2 = true;
      Service.svcReportAdmittedModB(this.convocatoria.id)
        .then((response) => {
          PDFService.reportAdmittedModB(this.convocatoria, response);
          this.load_report2 = false;
        })
        .catch((error) => {
          this.load_report2 = false;
          console.error("No genero el reporte", error);
        });
    },
  },
};
</script>